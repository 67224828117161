<template>
  <BasicModal :only-cancel-button="true">
    <template #content>
      <div v-if="missionName"> Mission: {{ missionName }} </div>
      <div v-if="slideName"> Slide: {{ slideName }} </div>
    </template>
  </BasicModal>
</template>

<script>
import { GET_MISSION_DETAILS, GET_SLIDE_DETAILS } from '../../../store/modules/cms/missions/actions'
import BasicModal from '@/components/elements/basicModal/BasicModal'

export default {
  name: 'DeleteVideoFailure',
  components: {
    BasicModal
  },
  props: {
    videoName: {
      type: String,
      required: true
    },
    slideId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      slideDetails: null,
      missionDetails: null
    }
  },
  computed: {
    slideIndex() {
      return this.slideDetails && this.missionDetails ? this.missionDetails.slides.findIndex(slide => slide.id === this.slideId) : null
    },
    slideName() {
      return this.slideDetails ? this.slideDetails.title : null
    },
    missionName() {
      return this.missionDetails ? this.missionDetails.name : null
    }
  },
  created() {
    this.$store.dispatch(GET_SLIDE_DETAILS, this.slideId).then(response => {
      this.slideDetails = response.data
      this.$store.dispatch(GET_MISSION_DETAILS, this.slideDetails.missionId).then(result => {
        this.missionDetails = result.data
      })
    })
  },
}
</script>

<style>

</style>